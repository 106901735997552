import React, { createContext, useState, useEffect } from 'react';
import { getAccessToken } from '../utils/auth';

export interface DBMessageSchema {
    message_id: number;
    chat_id: number | null;
    user_id: number;
    expert_id: number | null;
    expert_name: number | null;
    sender: string;
    medium: string;
    content: string;
    timestamp: string;
    hidden: boolean;
  }

export interface Message {
    id: string;
    text: string;
    isUser: boolean;
    expert_id?: string;
    expert_name?: string;
  }

export interface Conversation {
  chat_id: number;
  conversation_title: string;
  last_message_timestamp: string;
  messages?: Message[];
}

interface ConversationsContextType {
  conversations: Conversation[];
  fetchConversations: () => Promise<void>;
  fetchMessages: (chat_ids?: number[]) => Promise<void>;
  addConversation: (conversation: Conversation) => void;
  deleteConversation: (chat_id: number) => Promise<void>;
}

/** 
 * Provides conversation data to components.
 */
export const ConversationsContext = createContext<ConversationsContextType>({
  conversations: [],
  fetchConversations: async () => {},
  fetchMessages: async () => {},
  addConversation: () => {},
  deleteConversation: async () => {},
});

/** 
 * ConversationsProvider wraps components that need access to conversation data.
 */
export const ConversationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);

  const fetchConversations = async () => {
    try {
      const accessToken = getAccessToken();
      const response = await fetch('/api/conversations/conversation_list', {
        headers: { 'Authorization': `Bearer ${accessToken}` },
      });
      if (response.ok) {
        const data = await response.json();
        setConversations(prevConversations => {
          const existingIds = new Set(prevConversations.map(conv => conv.chat_id));
          const newConversations = data.filter((conv: Conversation) => !existingIds.has(conv.chat_id));
          const updatedExisting = prevConversations.map(conv => {
            const updated = data.find((newConv: Conversation) => newConv.chat_id === conv.chat_id);
            return updated || conv;
          });
          return [...newConversations, ...updatedExisting].sort((a, b) => 
            new Date(b.last_message_timestamp).getTime() - new Date(a.last_message_timestamp).getTime()
          );
        });
      } else {
        console.error('Failed to fetch conversations');
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const fetchMessages = async (chat_ids: number[] = []): Promise<void> => {
    try {
      const accessToken = getAccessToken();
      const queryParams = chat_ids.length ? `?chat_ids=${chat_ids.join(',')}` : '';
      const response = await fetch(
        `/api/conversations/all_user_messages${queryParams}`,
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }

      const data = await response.json();
      
      setConversations((prev) => prev.map((conv) => {
        // Get messages matching this conversation
        const matchingMessages = data
          .filter((msg: DBMessageSchema) => msg.chat_id === conv.chat_id)
          .map((msg: DBMessageSchema) => ({
            id: msg.message_id.toString(),
            text: msg.content,
            isUser: msg.sender === 'user',
            expert_id: msg.expert_id,
            expert_name: msg.expert_name
          }));

        // Merge existing and new messages, deduplicating by message ID
        const newMessages = new Map<string, Message>([
          ...(conv.messages || []).map((msg: Message) => [msg.id, msg]),
          ...matchingMessages.map((msg: Message) => [msg.id, msg])
        ]);

        return {
          ...conv,
          messages: Array.from(newMessages.values())
        };
      }));

    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const addConversation = (conversation: Conversation) => {
    setConversations((prev) => [conversation, ...prev]);
  };

  const deleteConversation = async (chat_id: number) => {
    try {
      const accessToken = getAccessToken();
      setConversations((prev) => prev.filter((conv) => conv.chat_id !== chat_id));
      await fetch(`/api/conversations/${chat_id}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${accessToken}` },
      });
      
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      await fetchConversations();
      await fetchMessages(); // Fetch all messages for all conversations - NOTE: we may need to decide how much we want to fetch at once in the future
    };
    initializeData();
  }, []);

  return (
    <ConversationsContext.Provider value={{ conversations, fetchConversations, fetchMessages, addConversation, deleteConversation }}>
      {children}
    </ConversationsContext.Provider>
  );
};