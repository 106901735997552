import React, { useState, KeyboardEvent, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--color-bg-primary);
`;

const LoginBox = styled.div`
  background-color: var(--color-bg-secondary);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: var(--color-text-primary);
  margin-bottom: 2rem;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  background-color: var(--color-bg-secondary);
  color: var(--color-text-primary);
  font-size: 1rem;
  transition: all 0.3s ease;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background: var(--color-secondary);
  color: var(--color-text-inverse);
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const ErrorMessage = styled.p`
  color: var(--color-error);
  margin-top: 1rem;
`;

interface LoginProps {
  csrfToken: string | null;
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  setCsrfToken: (value: string) => void;
}

const Login: React.FC<LoginProps> = ({ csrfToken, isLoggedIn, setIsLoggedIn, setCsrfToken }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [step, setStep] = useState('phone');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  const fetchCsrfToken = async () => {
    try {
      const response = await fetch('/auth/csrf-token', {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const token = response.headers.get('X-CSRF-Token');
        if (token) {
          console.log('New CSRF Token fetched');
          setCsrfToken(token);
        } else {
          console.error('CSRF Token not found in response headers');
        }
      } else {
        console.error('Failed to fetch CSRF token');
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };

  const handleRequestCode = async () => {
    try {
      const response = await fetch('/auth/request-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken || '',
        },
        body: JSON.stringify({ phone_number: phoneNumber }),
        credentials: 'include',
      });
      if (response.ok) {
        setStep('code');
        setError('');
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Error requesting verification code');
      }
    } catch (error) {
      setError('Error requesting verification code');
      console.error('Error requesting verification code:', error);
    }
    await fetchCsrfToken(); // Fetch new CSRF token after the request
  };

  const handleVerifyCode = async () => {
    try {
      const response = await fetch('/auth/verify-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken || '',
        },
        body: JSON.stringify({ phone_number: phoneNumber, code: verificationCode }),
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('access_token', data.access_token); // This is a security issue. Should not store access token in local storage.
        localStorage.setItem('isVerified', 'true');
        localStorage.setItem('timeOfVerification', new Date().toISOString());
        setIsLoggedIn(true);
        navigate('/chat');
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Error verifying code');
      }
    } catch (error) {
      setError('Error verifying code');
      console.error('Error verifying code:', error);
    }
    await fetchCsrfToken(); // Fetch new CSRF token after the request
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (step === 'phone') {
        handleRequestCode();
      } else {
        handleVerifyCode();
      }
    }
  };

  return (
    <LoginContainer>
      <LoginBox>
        <Title>Login</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {step === 'phone' ? (
          <>
            <Input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <Button onClick={handleRequestCode}>Request Code</Button>
          </>
        ) : (
          <>
            <Input
              type="text"
              placeholder="Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <Button onClick={handleVerifyCode}>Verify Code</Button>
          </>
        )}
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;
