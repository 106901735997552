import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SettingsContainer = styled.div<{ sidebarOpen?: boolean }>`
  padding: 2rem;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 482px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transform: translateX(${props => props.sidebarOpen ? '75%' : '0'});
    transition: transform 0.3s ease;
  }
`;

const Title = styled.h2`
  color: var(--color-text-primary);
  margin-bottom: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  color: var(--color-text-primary);
`;

const Input = styled.input<{ readOnly?: boolean }>`
  border-radius: 100px;
  padding: 0.5rem;
  background-color: ${props => props.readOnly ? 'var(--color-text-secondary)' : 'var(--color-bg-ternary)'};
  color: ${props => props.readOnly ? '#72767D' : 'var(--color-text-primary)'};
  cursor: ${props => props.readOnly ? 'not-allowed' : 'text'};
`;

const ReadOnlyMessage = styled.span`
  font-size: 0.8rem;
  color: #888;
  margin-top: 0.25rem;
`;

const Button = styled.button`
  border-radius: 100px;
  padding: 0.5rem 1rem;
  background: var(--color-secondary);
  color: var(--color-text-inverse);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const KrogerSection = styled.div`
  margin-top: 2rem;
`;

interface KrogerStatusProps {
  isLinked: boolean;
}

const KrogerStatus = styled.p<KrogerStatusProps>`
  color: ${props => props.isLinked ? 'var(--color-success)' : 'var(--color-error)'};
`;

const SuccessMessage = styled.span`
  font-size: 0.8rem;
  color: var(--color-success);
  margin-top: 0.25rem;
`;

const LogoutSection = styled.div`
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
`;

const LogoutButton = styled(Button)`
  background: var(--black);
`;

// Add interface for props
interface UserSettingsProps {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarOpen: boolean;
}

// Update component definition
const UserSettings: React.FC<UserSettingsProps> = ({ setIsLoggedIn, sidebarOpen }) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [krogerLinked, setKrogerLinked] = useState(false);
  const [krogerEmail, setKrogerEmail] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);

  useEffect(() => {
    fetchUserData();
    fetchKrogerStatus();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch('/auth/users/me', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setName(data.name || '');
        setPhoneNumber(data.phone_number || '');
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchKrogerStatus = async () => {
    try {
      const response = await fetch('/auth/kroger/status', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setKrogerLinked(data.is_linked);
        setKrogerEmail(data.account_email || '');
      }
    } catch (error) {
      console.error('Error fetching Kroger status:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('/auth/users/me', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify({ name }),
      });

      if (response.ok) {
        const data = await response.json();
        setName(data.name);
        setUpdateSuccess(true);
        setTimeout(() => setUpdateSuccess(false), 3000); // Hide message after 3 seconds
      } else {
        console.error('Failed to update settings');
        alert('Failed to update name. Please try again.');
      }
    } catch (error) {
      console.error('Error updating settings:', error);
      alert('An error occurred while updating the name. Please try again.');
    }
  };

  const handleLinkKroger = async () => {
    try {
      const response = await fetch('/auth/kroger/link', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        window.location.href = data.authorization_url;
      }
    } catch (error) {
      console.error('Error linking Kroger account:', error);
    }
  };

  const handleUnlinkKroger = async () => {
    try {
      const response = await fetch('/auth/kroger/unlink', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      if (response.ok) {
        setKrogerLinked(false);
        setKrogerEmail('');
      }
    } catch (error) {
      console.error('Error unlinking Kroger account:', error);
    }
  };

  const handleLogout = () => {
    // Clear authentication tokens
    localStorage.removeItem('access_token');
    localStorage.removeItem('isVerified');
    localStorage.removeItem('timeOfVerification');
    // Redirect to login page
    
    setIsLoggedIn(false);
    // Trigger a page refresh
    navigate('/');
    window.location.reload();
  };

  return (
    <SettingsContainer sidebarOpen={sidebarOpen}>
      <Title>User Settings</Title>
      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {updateSuccess && <SuccessMessage>Name updated successfully</SuccessMessage>}
        </InputGroup>
        <InputGroup>
          <Label htmlFor="phoneNumber">Phone Number</Label>
          <Input
            id="phoneNumber"
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            readOnly
          />
          <ReadOnlyMessage>Phone number cannot be changed</ReadOnlyMessage>
        </InputGroup>
        <Button type="submit">Save Settings</Button>
      </Form>
      <KrogerSection>
        <h3>Kroger Account</h3>
        {krogerLinked ? (
          <>
            <KrogerStatus isLinked={true}>Linked</KrogerStatus>
            {krogerEmail && <p>Kroger Email: {krogerEmail}</p>}
            <Button onClick={handleUnlinkKroger}>Unlink Kroger Account</Button>
          </>
        ) : (
          <>
            <KrogerStatus isLinked={false}>Not Linked</KrogerStatus>
            <Button onClick={handleLinkKroger}>Link Kroger Account</Button>
          </>
        )}
      </KrogerSection>
      <LogoutSection>
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      </LogoutSection>
    </SettingsContainer>
  );
};

export default UserSettings;