import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// Add this interface before the styled components
interface LogoProps {
  sidebarOpen?: boolean;
}

const HeaderContainer = styled.header<{ sidebarOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--color-bg-primary);
  transition: all 0.3s ease;
  position: fixed;
  top: 0;
  left: ${props => props.sidebarOpen ? '250px' : '0'};
  right: 0;
  z-index: 1000;

  @media (max-width: var(--sm)) {
    display: ${props => props.sidebarOpen ? 'none' : 'flex'};
  }
`;

const Logo = styled.h1<LogoProps>`
  color: var(--color-text-primary);
  margin: 0;
  font-size: 1.5rem;

  @media (max-width: var(--sm)) {
    font-size: 1.2rem;
    display: ${props => props.sidebarOpen ? 'none' : 'block'};
  }
`;

const NavLink = styled(Link)`
  color: var(--color-text-inverse);
  background: var(--color-text-primary);
  text-decoration: none;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
  border: 1px solid var(--color-border-muted);
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  margin-left: .5rem !important;
  &:hover {
    color: var(--color-text-primary);
    background-color: var(--color-bg-tertiary);
    opacity: 0.9;
  }

  @media (max-width: var(--sm)) {
    font-size: 12px;
    padding: 0.4rem 0.8rem;
    background: var(--color-text-primary);
    color: var(--color-text-inverse);
    &:hover {
        color: var(--color-text-primary);
        background-color: var(--color-bg-tertiary);
        opacity: 0.9;
    }
  }
`;

const LogoutButton = styled.button`
  color: var(--color-text-primary);
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
  border: 1px solid var(--color-border-muted);
  margin-left: .5rem !important;
  &:hover {
    background-color: var(--color-bg-tertiary);
  }

  @media (max-width: var(--sm)) {
    font-size: 12px;
    padding: 0.4rem 0.8rem;
  }
`;
const LoginButton = styled.button`
  color: var(--color-text-primary);
  background: var(--color-bg-primary);
  border: 1px solid var(--color-border-muted) !important;
  cursor: pointer;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
  margin-left: .5rem !important;
  &:hover {
    background-color: var(--color-bg-tertiary);
  }

  @media (max-width: var(--sm)) {
    font-size: 12px;
    padding: 0.4rem 0.8rem;
    display: none;
  }
`;

const SignupButton = styled.button`
  color: var(--color-text-inverse);
  background: var(--color-text-primary);
  border: 1px solid var(--color-border-muted) !important;
  cursor: pointer;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
  margin-right: .5rem !important;
  &:hover {
    opacity: 0.9;
  }

  @media (max-width: var(--sm)) {
    font-size: 12px;
    padding: 0.4rem 0.8rem;
  }
`;

const HeaderToggleButton = styled.button`
  background: var(--color-bg-primary);
  border: none;
  border-radius: 100px;
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background: var(--color-bg-tertiary);
  }

  svg {
    width: 24px;
    height: 24px;
    color: var(--color-text-primary);
  }

  @media (max-width: var(--sm)) {
    padding: 3px 8px;
    margin-right: 0.5rem;
    
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

interface HeaderProps {
    sidebarOpen: boolean;
    isLoggedIn: boolean;
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
    toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ sidebarOpen, isLoggedIn, setIsLoggedIn, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    // Clear authentication tokens
    localStorage.removeItem('access_token');
    localStorage.removeItem('isVerified');
    localStorage.removeItem('timeOfVerification');
    // Redirect to login page
    
    setIsLoggedIn(false);
    // Trigger a page refresh
    navigate('/');
    window.location.reload();
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignup = () => {
    navigate('/login');
  };

  return (
    <HeaderContainer sidebarOpen={sidebarOpen}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!sidebarOpen && location.pathname !== '/' && location.pathname !== '/login' && location.pathname !== '/password' && (
          <HeaderToggleButton 
            onClick={toggleSidebar}
            aria-label="Open sidebar"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path 
                d="M4 6L20 6M4 12L18 12M4 18L16 18" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round"
              />
            </svg>
          </HeaderToggleButton>
        )}
        <Logo sidebarOpen={sidebarOpen}>🍪 Chocolate Chip AI</Logo>
      </div>
      <nav>
        {!isLoggedIn &&(
          <>
            <SignupButton onClick={handleSignup}>Sign up</SignupButton>
            <LoginButton onClick={handleLogin}>Login</LoginButton>
          </>
        )}
      </nav>
    </HeaderContainer>
  );
};

export default Header;
