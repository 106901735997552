import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from './styles/GlobalStyle';
import AppContent from './components/AppContent';
import { ConversationsProvider } from './contexts/ConversationsContext';

const App: React.FC = () => {
    const [csrfToken, setCsrfToken] = useState<string | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        // Initialize isLoggedIn by checking localStorage
        return !!localStorage.getItem('access_token');
    });

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await fetch('/auth/csrf-token', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const token = response.headers.get('X-CSRF-Token');
                    if (token) {
                        console.log('Initial CSRF Token fetched');
                        setCsrfToken(token);
                    } else {
                        console.error('CSRF Token not found in response headers');
                    }
                } else {
                    console.error('Failed to fetch CSRF token');
                }
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();
    }, []);

    return (
        <Router>
            <GlobalStyle />
            <ConversationsProvider>
            <AppContent 
                isLoggedIn={isLoggedIn} 
                setIsLoggedIn={setIsLoggedIn} 
                csrfToken={csrfToken}
                setCsrfToken={setCsrfToken}
            />
            </ConversationsProvider>
        </Router>
    );
};

export default App;
