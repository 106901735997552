import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Header from './Header';
import Sidebar from './Sidebar';
import ConversationPanel from './ConversationPanel';
import Login from './Login';
import UserSettings from './UserSettings';
import OAuthCallback from './OAuthCallback';
import MainPage from './MainPage';
import Secret from './Secret';

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const MainContent = styled.main<{ sidebarOpen: boolean }>`
  flex-grow: 1;
  display: flex; // Add this line to make MainContent a flex container
  flex-direction: column; // Ensure children are stacked vertically
  transition: margin-left 0.3s ease;
  margin-left: ${props => props.sidebarOpen ? '250px' : '0'};
  margin-top: 60px; // Adjust this value based on your header height
  padding: 0;
  overflow-y: auto;
  * {
    border: none;
    margin: 0;
  }
`;
interface AppContentProps {
  csrfToken: string | null;
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  setCsrfToken: React.Dispatch<React.SetStateAction<string | null>>;
}

interface Conversation {
  chat_id: number;
  conversation_title: string;
  last_message_timestamp: string;
}

const AppContent: React.FC<AppContentProps> = ({ csrfToken, isLoggedIn, setIsLoggedIn, setCsrfToken }) => {
  const [sidebarOpen, setSidebarOpen] = useState(() => {
    return window.innerWidth > 768;
  });

  useEffect(() => {
    const checkScreenSize = () => {
      const root = document.documentElement;
      const mdBreakpoint = getComputedStyle(root).getPropertyValue('--md').trim();
      setSidebarOpen(window.innerWidth > parseInt(mdBreakpoint));
    };
    
    // Run the check on component mount
    checkScreenSize();

    // Optionally, add a resize event listener to update on window resize
    window.addEventListener('resize', checkScreenSize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const [newChatCreated, setNewChatCreated] = useState(false);

  const handleNewChat = () => {
  setNewChatCreated(true);
};

  useEffect(() => {
    if (newChatCreated) {
      setNewChatCreated(false);
    }
  }, [newChatCreated]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogout = () => {
    // Clear authentication tokens
    localStorage.removeItem('access_token');
    localStorage.removeItem('isVerified');
    localStorage.removeItem('timeOfVerification');
    // Redirect to login page
    
    setIsLoggedIn(false);
    // Trigger a page refresh
    navigate('/');
    window.location.reload();
  };
  return (
    <Routes>
      <Route path="/" element={<MainPage csrfToken={csrfToken} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>} />
      <Route path="/login" element={<Login csrfToken={csrfToken} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setCsrfToken={setCsrfToken}/>} />
      <Route
        path="/chat"
        element={
          <AppContainer>
            <Sidebar 
              isOpen={sidebarOpen} 
              toggleSidebar={toggleSidebar}
              newChatCreated={newChatCreated}
              handleLogout={handleLogout}
              isLoggedIn={isLoggedIn}
            />
            <MainContent sidebarOpen={sidebarOpen}>
              <Header 
                sidebarOpen={sidebarOpen} 
                isLoggedIn={isLoggedIn} 
                setIsLoggedIn={setIsLoggedIn} 
                toggleSidebar={toggleSidebar}
              />
              <ConversationPanel 
                csrfToken={csrfToken} 
                setCsrfToken={setCsrfToken} 
                sidebarOpen={sidebarOpen}
                onNewChat={handleNewChat}
              />
            </MainContent>
          </AppContainer>
        }
      />
      <Route
        path="/settings"
        element={
          <AppContainer>
            <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} newChatCreated={newChatCreated} handleLogout={handleLogout} isLoggedIn={isLoggedIn}/>
            <MainContent sidebarOpen={sidebarOpen}>
              <Header sidebarOpen={sidebarOpen}
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              toggleSidebar={toggleSidebar}/>
              <UserSettings sidebarOpen={sidebarOpen} setIsLoggedIn={setIsLoggedIn}/>
            </MainContent>
          </AppContainer>
        }
      />
      <Route path="/oauth-callback" element={<OAuthCallback />} />
    </Routes>
  );
};

export default AppContent;
