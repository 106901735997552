import React, { useState, useRef, useEffect , useContext} from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Markdown from 'react-markdown'
import { ConversationsContext, Message as MessageType, Conversation } from '../contexts/ConversationsContext';

const PanelContainer = styled.div<{ hasMessages: boolean; sidebarOpen?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--color-bg-primary);
  overflow: hidden;
  justify-content: ${props => props.hasMessages ? 'flex-start' : 'center'};
  position: relative;

  @media (max-width: var(--sm)) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transform: translateX(${props => props.sidebarOpen ? '75%' : '0'});
    transition: transform 0.3s ease;
  }
`;

const MessageListWrapper = styled.div`
  width: 100%;
  max-width: none;
  margin: 0;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding top 
`;

const MessageList = styled.div<{ hasMessages: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  transition: flex 0.3s ease;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  align-items: center;
`;

const Message = styled.div<{ isUser: boolean }>`
  max-width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  word-wrap: break-word;
  white-space: pre-wrap;
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  background-color: ${(props) => (props.isUser ? 'var(--color-bg-tertiary)' : 'var(--color-bg-primary)')};
  max-width: ${props => props.isUser ? '80%' : '100%'};
  color: var(--color-text-primary);
  transition: all 0.3s ease;
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }

  @media (max-width: var(--sm)) {
    margin-top: 0; /* Reset the margin-top on smaller screens */
  }

  &:first-child {
    @media (max-width: var(--sm)) {
      margin-top: 5rem; /* Apply a larger margin to the first message only */
    }
  }
`;

const InputArea = styled.form<{ hasMessages: boolean }>`
  display: flex;
  position: ${props => props.hasMessages ? 'relative' : 'absolute'};
  left: 50%;
  transform: ${props => props.hasMessages ? 'translateX(-50%)' : 'translate(-50%, -50%)'};
  top: ${props => props.hasMessages ? 'auto' : '50%'};
  max-width: 800px;
  width: calc(100% - 2rem);
  gap: 0.5rem;
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  height: 3.5rem;
  border-radius: 100px;
  background-color: var(--color-bg-secondary);
  position: relative;
`;

const Input = styled.textarea`
  display: flex;
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  font-size: 1rem;
  margin: 0 auto;
  background-color: transparent;
  color: var(--color-text-primary);
  border: none;
  resize: none;
  overflow-y: auto;
  font-family: 'Roboto', sans-serif;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--color-text-secondary);
  }

  /* Slim scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary);
    border-radius: 10px;
  }
`;

const SendButton = styled.button<{ hasText: boolean }>`
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  background-color: transparent;
  color: var(--color-text-inverse);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  svg path {
    fill: ${({ hasText }) => (hasText ? 'var(--color-secondary)' : 'var(--color-text-secondary)')};
  }
`;

const LoadingIndicator = styled.div`
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-border);
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: translateY(-50%) rotate(0deg); }
    100% { transform: translateY(-50%) rotate(360deg); }
  }
`;

const WelcomeText = styled.div<{sidebarOpen?: boolean }>`
  color: var(--color-text-primary);
  font-size: 2rem;
  font-weight: 500;
  position: absolute;
  left: 1rem;
  right: 1rem;
  bottom: calc(50% + 2rem);
  transform: translateY(-50%);
  text-align: center;

  @media (max-width: var(--md)) {
    font-size: 1.5rem;
  }

  @media (max-width: var(--sm)) {
    font-size: 1.35rem;
    margin-left: ${props => props.sidebarOpen ? '75%' : '0'};
    transition: margin-left 0.3s ease;
  }
`;

const TypingIndicator = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  align-self: flex-start;
  margin-left: 1rem;

  span {
    width: 8px;
    height: 8px;
    background: var(--medium-grey-002);
    border-radius: 50%;
    animation: bounce 1.5s infinite;
    
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  @keyframes bounce {
    0%, 60%, 100% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-4px);
    }
  }
`;

interface ConversationPanelProps {
  csrfToken: string | null;
  setCsrfToken: React.Dispatch<React.SetStateAction<string | null>>;
  sidebarOpen: boolean;
  onNewChat: () => void;
}

const ConversationPanel: React.FC<ConversationPanelProps> = ({ csrfToken, setCsrfToken, sidebarOpen, onNewChat }) => {
  const {state} = useLocation();
  const { conversations, addConversation } = useContext(ConversationsContext);
  const conversation = conversations.find(conv => conv.chat_id === state?.chat_id);
  const [newChatId, setNewChatId] = useState<number | undefined>(undefined);
  const [messages, setMessages] = useState<MessageType[]>(conversation?.messages || []);
  const [input, setInput] = useState('');
  const messageListRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate(); // TODO: check of auth failure would look like with websockets first
  const [userId, setUserId] = useState<string | null>(null);
  const socketRef = useRef<WebSocket | null>(null);
  

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await fetch('/auth/users/me', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserId(data.user_id || '');
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserId();
  }, []);


  useEffect(() => {
    // Focus the input field when the component mounts
    inputRef.current?.focus();
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
    // Re-focus the input field after each message
    inputRef.current?.focus();
  }, [messages]);

  useEffect(() => {
    if (!csrfToken) {
      const fetchCsrfToken = async () => {
        try {
          const response = await fetch('/auth/csrf-token', {
            method: 'GET',
            credentials: 'include',
          });
    
          if (response.ok) {
            const token = response.headers.get('X-CSRF-Token');
            if (token) {
              console.log('New CSRF Token fetched');
              setCsrfToken(token);
            } else {
              console.error('CSRF Token not found in response headers');
            }
          } else {
            console.error('Failed to fetch CSRF token');
          }
        } catch (error) {
          console.error('Error fetching CSRF token:', error);
        }
      };
      fetchCsrfToken();
    }
  }, );

  useEffect(() => {
    if (conversation?.messages && conversation.messages.length > 0) {
      setMessages(conversation.messages);
    }
  }, [conversation?.messages, setMessages]);

  useEffect(() => {
    // Initialize WebSocket connection with CSRF token
    if (!csrfToken || !userId) {
      return;
    }
    if (state?.chat_id) {
      console.log('Loaded conversation with chat_id:', state?.chat_id);
      // Add logic to fetch and display messages for the selected chat_id
    } else {
      console.log('No chat_id specified -> new chat');
      setMessages([]);
    }

    const socketUrl = `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}/api/media?csrfToken=${encodeURIComponent(csrfToken || '')}`;
    const socket = new WebSocket(socketUrl);
    socketRef.current = socket;

    socket.onopen = () => {
      console.log('WebSocket connection established');
      socket.send(JSON.stringify({ event: "connected", user_id: userId, chat_id: state?.chat_id }));
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received message from WebSocket:', data);
      
      if (data.event === 'connected') {
        console.log('Connection confirmed by server');

        if (!state?.chat_id && data.chat_id) {
          setNewChatId(data.chat_id);
        }

      } else if (data.event === 'setup-done') { 
        console.log('Server setup completed');
      } else if (data.event === 'text') {
        setMessages((prevMessages) => {
          // Find the last message from the same expert
          const reversedIndex = [...prevMessages].reverse().findIndex(
            msg => !msg.isUser && msg.expert_id === data.expert_id
          );
          const lastExpertMessageIndex = reversedIndex === -1 ? -1 : prevMessages.length - 1 - reversedIndex;

          // Check if there are any user messages after the last expert message
          const hasUserMessageAfterExpert = prevMessages
            .slice(lastExpertMessageIndex + 1)
            .some(msg => msg.isUser);
          
          // If found a previous message from same expert and no user messages after it, append
          if (lastExpertMessageIndex !== -1 && !hasUserMessageAfterExpert) {
            const updatedMessages = [...prevMessages];
            updatedMessages[lastExpertMessageIndex] = {
              ...updatedMessages[lastExpertMessageIndex], 
              text: updatedMessages[lastExpertMessageIndex].text + data.text
            };
            return updatedMessages;
          }
          // Otherwise create new message
          return [...prevMessages, {
            id: data.item_id,
            text: data.text,
            isUser: false,
            expert_id: data.expert_id,
            expert_name: data.expert_name,
          }];
        });
      } else if (data.event === 'truncated') {
        if (data.rules_violated) {
          setMessages(prevMessages => prevMessages.filter(msg => msg.id !== data.item_id));
        }
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setMessages((prevMessages) => [...prevMessages, {
        id: Date.now().toString(),
        text: "An error occurred. Please try again later.",
        isUser: false,
      }]);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => {
      socket.close();
    };
  }, [csrfToken, userId, state?.chat_id, setNewChatId]);


  // Function to send message via WebSocket
  const sendMessage = (userMessage: MessageType) => {
    try {
      if (socketRef.current?.readyState === WebSocket.OPEN) {
        console.log("Sending message to WebSocket:", JSON.stringify({
          event: "text", text: userMessage.text
       }));
        socketRef.current.send(JSON.stringify({
           event: "text", text: userMessage.text
        }));
      } else {
        throw new Error('WebSocket is not open');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      
      setMessages((prevMessages) => [...prevMessages, {
        id: Date.now().toString(),
        text: "Unable to send message. Please try again later.",
        isUser: false,
      }]);
    } finally {
      setLoading(false);
      inputRef.current?.focus(); // Re-focus the input after sending a message
    }
  };

    // Function to adjust the height of the textarea
  const adjustTextareaHeight = (textarea: HTMLTextAreaElement, container: HTMLDivElement) => {
      textarea.style.height = 'auto'; // Reset textarea height
      const newHeight = Math.min(textarea.scrollHeight, 5 * 1.5 * 16); // Calculate new height up to 5 lines
      textarea.style.height = `${newHeight}px`; // Set textarea height
      container.style.height = `${newHeight + 16}px`; // Set container height with padding
  };
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim()) {
      // If this is a new chat (no chat_id in state)
      if (!state?.chat_id) {
        onNewChat();  // Call this when creating a new chat
      }

      if (messages.length === 0) {
        const newConversation: Conversation = {
          chat_id: newChatId!,
          conversation_title: "New Chat",
          last_message_timestamp: new Date().toISOString(),
          messages: []
        };
        addConversation(newConversation);
        console.log('Added new conversation with chat_id:', newChatId);
      }
      
      const userMessage: MessageType = {
        id: Date.now().toString(),
        text: input.trim(),
        isUser: true,
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInput('');

      setLoading(true);

      sendMessage(userMessage);
      inputRef.current!.rows = 1;
      inputRef.current!.style.height = 'auto';
      inputContainerRef.current!.style.height = 'auto';
    }
  };


  return (
    <PanelContainer hasMessages={messages.length > 0} sidebarOpen={sidebarOpen}>
      <MessageListWrapper>
        <MessageList ref={messageListRef} hasMessages={messages.length > 0} aria-live="polite">
          {messages.map((message) => (
            <Message key={message.id} isUser={message.isUser} aria-label={`${message.isUser ? 'You' : 'AI'} said: ${message.text}`}>
              {message.expert_id && (
                <>
                  <span><strong>
                    {message.expert_name 
                      ? `${message.expert_name} (ID: ${message.expert_id})`
                      : `Expert ID: ${message.expert_id}`
                    }
                  </strong></span>
                  <br />
                </>
              )}
              <Markdown>{message.text}</Markdown>
            </Message>
          ))}
          {loading && (
            <TypingIndicator aria-label="AI is typing">
              <span></span>
              <span></span>
              <span></span>
            </TypingIndicator>
          )}
        </MessageList>
      </MessageListWrapper>
      {messages.length === 0 && (
        <WelcomeText>How can your experts help today?</WelcomeText>
      )}
      <InputArea onSubmit={handleSubmit} hasMessages={messages.length > 0}>
        <InputContainer ref={inputContainerRef}>
          <Input
            ref={inputRef}
            value={input}
            rows={1}
            onChange={(e) => {
              setInput(e.target.value);
              adjustTextareaHeight(e.target as HTMLTextAreaElement, inputContainerRef.current!);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevents the default newline behavior
                handleSubmit(e); // Calls the submit function when Enter is pressed
              }
            }}
            placeholder="Ask your expert anything"
            aria-label="Type your message"
            disabled={loading}
          />
          <SendButton type="submit" aria-label="Send message" hasText={input.trim().length > 0} disabled={loading}>
            <svg width="25" height="25" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_420_146" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="2" width="29" height="35">
                <rect x="1.78711" y="2.79999" width="28.1971" height="33.6" fill="var(--color-btn-fill)" />
              </mask>
              <g mask="url(#mask0_420_146)">
                <path d="M25.0495 20.895L6.95636 29.995C6.56473 30.1817 6.19268 30.1409 5.84022 29.8725C5.48776 29.6042 5.31152 29.2134 5.31152 28.7V10.5C5.31152 9.98669 5.48776 9.59585 5.84022 9.32752C6.19268 9.05919 6.56473 9.01835 6.95636 9.20502L25.0495 18.305C25.5391 18.5617 25.7838 18.9934 25.7838 19.6C25.7838 20.2067 25.5391 20.6384 25.0495 20.895ZM7.66129 26.6L21.5836 19.6L7.66129 12.6V17.5L14.7106 19.6L7.66129 21.7V26.6Z" fill="var(--color-text-secondary)" />
              </g>
              <mask id="mask1_420_146" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="29" height="34">
                <rect x="0.612305" width="28.1971" height="33.6" fill="var(--color-btn-fill)" />
              </mask>
              <g mask="url(#mask1_420_146)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0236 11.655C22.0837 12.775 21.6137 14.14 21.6137 15.75C21.6137 14.14 21.1438 12.775 20.2039 11.655C19.264 10.535 18.1185 9.97501 16.7674 9.97501C18.1185 9.97501 19.264 9.41501 20.2039 8.29501C21.1438 7.17501 21.6137 5.81001 21.6137 4.20001C21.6137 5.81001 22.0837 7.17501 23.0236 8.29501C23.9635 9.41501 25.109 9.97501 26.4601 9.97501C25.109 9.97501 23.9635 10.535 23.0236 11.655Z" fill="var(--color-text-secondary)" />
              </g>
            </svg>
        </SendButton>
        </InputContainer>
      </InputArea>    
      {messages.length === 0 && (
        <WelcomeText>How can your experts help today?</WelcomeText>
      )}
    </PanelContainer>
  );
};

export default ConversationPanel;