import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const OAuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');

    if (code) {
      // Send the code to your backend to exchange for tokens
      exchangeCodeForTokens(code);
    } else {
      // Handle error
      console.error('No code received from OAuth provider');
      navigate('/login');
    }
  }, [location, navigate]);

  const exchangeCodeForTokens = async (code: string) => {
    try {
      // Replace with your actual API endpoint
      const response = await fetch('/api/oauth/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });

      if (response.ok) {
        const data = await response.json();
        // Store the tokens securely (e.g., in HttpOnly cookies via the backend)
        // Update the authentication state
        // Redirect to the main app
        navigate('/chat');
      } else {
        throw new Error('Failed to exchange code for tokens');
      }
    } catch (error) {
      console.error('Error exchanging code for tokens:', error);
      navigate('/login');
    }
  };

  return <div>Processing OAuth callback...</div>;
};

export default OAuthCallback;